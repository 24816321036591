@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(0.3rem);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveBackAndForw {
  0% {
      transform: translateX(0);
  }

  50% {
      transform: translateX(6px);
  }
  100% {
      transform: translateX(0);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}