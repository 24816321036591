@import '../../styles/variables';

nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  z-index: 9999;
  height: 5rem;
}

.navbar{
  width: 100%;
  transition: 1s;
}

.navbar-brand{
  font-size: 2em;
  cursor: pointer;
}

.icon-bar{
  background-color: $white;
}

.nav{
  padding: 5px 20px;
  display: flex;
  margin: 1rem 10px;
} 

.nav li{
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.nav-link {
  text-decoration: none;
  &:focus {
    border-bottom: 1px solid;
  }
}

.is-active {
  border-bottom: 1px solid;
}

ul.navbar-nav li:after{
  content: '';
  display: block;
  border-bottom: 1px solid;
  transform: scaleX(0);
  transition: .2s ease-in-out;
}

.nav li:hover:after{
  transform: scaleX(1);
}

ul {
  list-style-type: none;
  margin: 0;
}

li.media {
  padding: 2px;
  margin: 0 0.5rem;
}

ul.navbar-media {
  padding: 0;
}

.media-img {
  width: 25px;
  filter: invert(120%);
}

.med4-img {
  filter: brightness(5);
}

.med1-img {
  filter: brightness(30);
}

.media-btn {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: all 0.6s linear;
  &:hover {
    transition: all 0.6s linear;
  }
  &:active, :focus {
    transition: all 0.6s linear;
  }          
}

.wrap-dropdown{
  height: 100%;
  margin-left: 3%;
}

@media (max-width: 720px) {
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3%;
  }
 
  ul.navbar-nav{
    padding: 5px 20px;
    display: flex;
    margin: 0 10px;
  } 

  ul.navbar-media {
    display: flex;
   }

   .media-btn {
    width: 35px;
    height: 35px;
    img.media-img {
      width: 20px;
    }
  }

  .wrap-dropdown{
    margin-left: 0%;
  }

  .container-dropdown {
    justify-content: center;
    align-items: center;
  }
}

.container-dropdown {
  position:relative;
  display: flex;
  height: 100%;

  button {
    border: 0;
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;

    img {
      transition: all 0.6s linear;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
        transition: all 0.6s linear;
      }
    }
  }
}

.dropdown {
  position: absolute;
  top: 75%;
  left: 0;
  width: 50px;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: rgba(83, 80, 80, 0.2);
  backdrop-filter: saturate(140%) blur(20px);
}

ul.dropdown__drop-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 6px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li:first-child {
    border-radius: 5px 5px 0 0 ;
  }

  li:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
}


