@import '../../styles/variables';

.projects-page {
  justify-content: center;
}

.gallery-projects {
  margin-top: 2rem;
  margin-bottom: 5rem;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
}

.projects-wrap {
  width: 1000px;
  height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-links {
  margin-right: 2rem;

  &:hover {
    .MuiIconButton-root {
      box-shadow: 0 0 0 0;
      text-shadow: 0 0 0 0;
    }
  }
}