@import '../../styles/variables';

.media-container {
  z-index:200;
  position: fixed;
  bottom:0;
  transition: opacity .4s ease;
  width: inherit; 
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: saturate(180%) blur(20px);
}