@import '../../styles/animation';
@import '../../styles/variables';

.wrap{
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 4rem;
  min-height: 85vh;
  max-height: fit-content;
  position: relative;
}

.title-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  padding-top: 3%;
  width: 94%;
}
section.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  position: relative;
}

section.container-body{
  margin-bottom: 5em;
}

section.projects-home-wrap {
  height: 100%;
}


section.projects-inner {
  padding-top: 0rem;
}

.home {
  width: 100%;
  height: 100%;
}

.portfolio-title {
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: bold;
  color: transparent;
  flex-wrap: wrap;
  margin: 2rem 0 2rem 0;
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
  animation: fadein 2s ease backwards;
  padding-bottom: 0.5rem;
}

span.role {
  background-color: $primaryWhiteColor;
  color: transparent;
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
          animation-delay: 0.9s;
}

span.place {
  animation-delay: 0.9s;
  padding: 1rem;
} 

.highlight {
  padding: 1rem;
  width: fit-content;
}

p.header-paragraph > span{
  animation: fadein 1s ease backwards;
}

.header-paragraph {
  margin: 0rem;
  max-width: 540px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  white-space: break-spaces;
}

div.header-paragraph-wrap {
  height: fit-content;
  max-width: 540px;
}

.header__letter {
  margin-top: 0.8rem;
  margin-right: 0.1rem;
  display: block;
  font-size: 1.1rem;
}

.title-page__nav-box {
  width: 65%;
  display: flex;  
  align-items: flex-end;
  flex-direction: column;
  :last-child:hover .nav-box-img {
    animation: MoveUpDown 2s linear infinite;
  }
  > a {
    text-decoration: none;
  }
}

.title-page__nav-item {
  display: flex;
  animation: fadein 3s ease .3s backwards;
  animation-delay: 3.3s;
}

span.nav-box-text {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid;
      transform: scaleX(0);
      transition: .2s ease-in-out;
    }
    &:hover:after{
      transform: scaleX(1);
    }
}

span.nav-box-img {
  margin-left: 0.5rem;
}

.icon-holder a {
  text-decoration: none;
  font-size: 1.2rem;
  margin-top: 0.8rem;
  margin-right: 1rem;
  img.project-icon {
    margin: 0 0 0 0.4rem;

  }

  &:hover {
    .project-icon {
      animation: moveBackAndForw 1s linear infinite;
    }
  }
}

.containerGit {
  width: 100%;

  ul {
    height: 100%;
    overflow: auto;
    top: 10em;
    margin-top: 5em;
    justify-content: center;
    width: 100%;
    padding: 0;

  li {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      
    }
  }
}

ul.projects-list {
  padding-left: 0;

}
.project-card {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(30rem,auto));
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  align-items: center;
}


.card {
  height: fit-content;
  display: flex;
  margin-top: auto;
  flex-wrap: wrap;
  padding: 2.5rem 0;
  border-bottom: 1px solid $lineTransparentColor;
}

.card:first-child {
  border-top: 1px solid $lineTransparentColor;
}

.card:last-child {
  border-bottom: 0px;
}

.content {
  display: flex;
  font-size: 1em;
  flex-direction: column;
  text-align: left;
}

.title {
  font-size: 2.2rem;
  font-weight: bold;
  height: 100%;
  display: flex;  
  align-items: center;
}

.project-card-img {
  width: 250px;
  margin: 20px;
}

.description {
  font-size: 1.5em;
  width: 100%;
  font-weight: lighter;
  text-align: left;
}

.icon-holder {
  width: fit-content;
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 20px;
    }
  }
}

.container-p-nav {
  display: flex;
  align-items: flex-end;
  padding-bottom: 8.5rem;
}

@media (max-width: 1300px) {
  .containerGit {
    width: 100%;
    ul {
      margin-top: 4em;
      justify-content: center;
      width: 100%;
      overflow: auto;
      padding: 0;
  
    li.card {
        width: fit-content;
        margin: 1em 0;
        display: flex;
        width: 100%;
        align-items: center;
        font-size: 0.7;
      }
    }
  }

  .content {
    width: 100%;
  }

  .portfolio-title {
    font-size: 3rem;
  }
}

@media (max-width: 720px) {
  .container-p-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 3.5rem;
  }

  .title-page__nav-box {
    margin-top: 4rem;
    width: auto;
    text-align: left;
    align-items: flex-start;
  }

  section.container{
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.wrap{
  padding: 0 2% 8% 2%;
  min-height: 80vh;
}

.title-page {
  justify-content: center;
  width: 96%;
  display: flex;
  flex-direction: column;
}

.portfolio-title {
  font-size: 2rem;
}

span.nav-box-text, span.nav-box-img {
  font-size: 1rem;
}

  h1.portfolio-title {
    top: 2em;
    margin: 0;
  }
  .container-projects {
    top: 5em;
  }
  .containerGit {
  
    ul {
      margin-top: 3em;
    }
  }
  .content {
    width: 100%;
    font-size: 0.8em;
  }

  .title {
    font-size: 1.8em;
  }
}

@media (max-width: 530px) {
  .wrap, .title-page{
    justify-content: center;
    width: 95%;
    min-height: 82vh;
    display: flex;
    flex-direction: column;
    h1 {
      margin: 0;
    }  
  }

  .header__letter:nth-child(n){
    font-size: 0.8rem;
  }

  .portfolio-title{
    font-size: 2rem;
  }

  .content {
    font-size: 1em;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .project-card{
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(22rem, auto));
  }

  .description {
    font-size: 0.8em;
  }

  .git-repo-link, .git-deploy-link {
    font-size: 0.8em;
  }

  span.nav-box-text, span.nav-box-img {
    font-size: 0.8em;
  }

  .card:last-child {
    margin-bottom: 2rem;
  }
}

@media (max-width: 350px) {
  .project-card{
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(18rem, auto));
  }  
}