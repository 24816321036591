@import '../../styles/variables';

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  background-attachment: fixed;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
}