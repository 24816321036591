@import '../../styles/variables';

.art-page {
  justify-content: center;
}

.gallery {
  margin-top: 2rem;
  margin-bottom: 5rem;
  padding: 2%;
  display: grid;
  grid-gap: 30px;
  width: 96%;
  height: 100%;
  grid-auto-rows: 150px;
  grid-template-columns: repeat(6, 1fr);
}

.gallery-img  {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background: $black;
  color: $white;
  grid-column-start: auto;
  grid-row-start: auto;
  color: $white;
  background-size: cover;
  background-position: center;
  box-shadow: -2px 2px 10px 0px rgba($shadowColor, 0.4);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  counter-increment: item-counter;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
  }
  
  &:hover {
    transform: scale(1.05);
    
    &:after {
      opacity: 0;
    }
  }
  
  &:nth-child(1), :nth-child(6){
    grid-row-end: span 2;
    grid-column: span 2;
  }

  &:nth-child(2) {
    grid-row-end: span 2;
    grid-column: span 2;
  }

  &:nth-child(3) {
    grid-row-end: span 2;
    grid-column: span 2;
  }

  &:nth-child(4) {
    grid-row-end: span 2;
    grid-column: span 2;
  }

  &:nth-child(5) {
    grid-row-end: span 2;
  }

  &:nth-child(6) {
    grid-row-end: span 2;
    grid-column: span 2;
  } 
}

  @media screen and (max-width: 970px) {
    .gallery {
    grid-row-end: span 2;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media screen and (max-width: 740px) {
  .gallery {
    display: flex;
    flex-direction: column;
  }

  .gallery-img {
    height: 400px;
  }

  .gallery-img:nth-child(6) {
    height: 750px;
  }
}

@media screen and (max-width: 500px) {
  .gallery {
    display: flex;
    flex-direction: column;
  }

  .gallery-img {
    height: 280px;
  }

  .gallery-img:nth-child(6) {
    height: 490px;
  }
}